import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Something from "./pages/Something";
import Hype from "./pages/Hype";
import AlsoHype from "./pages/AlsoHype";
import NoPage from "./pages/NoPage";
import './index.css';

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="something" element={<Something />} />
					<Route path="hype" element={<Hype />} />
					<Route path="hype-flemish-edition" element={<AlsoHype />} />
					<Route path="*" element={<NoPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

ReactDOM.render(<App />, document.getElementById("root"));