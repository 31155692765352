import React from 'react';

function Home() {
	return (
		<main className='snap-y snap-mandatory overflow-y-scroll h-screen'>
			<section className="h-screen flex justify-center items-center bg-emerald-600 text-slate-50 snap-start">
				<div>
					<h1 className="text-4xl sm:text-7xl font-bold drop-shadow">Ja dan hoop je dat hier al wat leuks staat maar helaas..</h1><br />
					<h2 className="text-2xl font-bold drop-shadow">Je zult het met de countdown moeten doen</h2>
				</div>
			</section>

			<section className="h-screen flex justify-center items-center bg-red-600 text-slate-50 snap-start">
				<h1 className="text-6xl sm:text-7xl font-bold drop-shadow">Niks</h1>
			</section>

			<section className="h-screen flex justify-center items-center bg-indigo-600 text-slate-50 snap-start">
				<h1 className="text-6xl sm:text-7xl font-bold drop-shadow">Noppes</h1>
			</section>

			<section className="h-screen flex justify-center items-center bg-violet-600 text-slate-50 snap-start">
				<h1 className="text-6xl sm:text-7xl font-bold drop-shadow">Nada</h1>
			</section>
		</main>
	);
}

export default Home;