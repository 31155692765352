import React from 'react';

function Something() {
	return (
		<div>
			<h1>Hier gebeurd nog niet zo veel</h1>
		</div>
	);
}

export default Something;