import React, { Component } from "react";
import Countdown from "./Countdown.jsx";
// import logo from "./logo.svg";
// import github from "./github.png";

class AlsoHype extends Component {
	render() {
		const currentDate = new Date();
		const year =
			currentDate.getMonth() === 11 && currentDate.getDate() > 23
				? currentDate.getFullYear() + 1
				: currentDate.getFullYear();
		return (
			<div className="App">
				<div className="App-header">
					{/* <img src={logo} className="App-logo" alt="logo" /> */}
					<h2>Tom en Louis hebben de sleutel in:</h2>
					<Countdown date={`${year}-04-25T11:00:00`} />
				</div>
			</div>
		);
	}
}

export default AlsoHype;